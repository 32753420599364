strong {
    font-family: Noto Sans, sans-serif;
}

p {
    font-family: Noto Sans, sans-serif;
}

li {
    font-family: Noto Sans, sans-serif;
}

.user-message {
    font-family: Noto Sans, sans-serif;
    color: white;
    font-weight: bold;
}

.ref-button {
    cursor: pointer;
    font-family: Noto Sans, sans-serif;
    color: white;
    font-weight: bold;
    border-width: 0;
}

button.ref-button:hover {
    background: 'black !important'
}